import React from 'react';
import Nav from '../components/Nav/Nav';

export default function aboutPage() {
  return (
    <div>
      <Nav />
      <h1>About Cosmozo</h1>
      <p>
        What is there to say?
        <br />
        This is a place in the world, where I can play with code and post
        pictures.
        <br />
        I can also play with styles.
        <br />
        There's likely to be naked breasts, vulvas, penises, and other naughty
        business.
        <br />
        Enjoy.
        <br />
      </p>
    </div>
  );
}
